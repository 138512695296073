import { fileType } from "../helpers/utils";
import execs from "../helpers/commands/execs";

const execContent = "Error: can not print an executable file.";

export const files = {
    "about.txt": {
        name: "about.txt",
        content: [
            "Hi there 👋 I currently work as a Principal at Gamma Data (https://gammadata.io). ",
            "We build Data-Platforms for the Enterprise.",
            "",
            "-----",
            "My side projects include",
            "✊ epicarc.io",
            "✊ skicounselling.com",
            "✊ sleeplikegoldilocks.com",
            "",
            "-----",
            "Using and manipulating Data in cloud is what excites me.",
            "I am a specialist GCP consultant with a 15-year history in IT, having worked across multiple, enterprise scale GCP implementations ",
            "over the past 5 years including Wesfarmers Health, Department of Premier and Cabinet, ANZ Bank ",
            "Startup's I've helped with include notion.ai & gopassport.health ",
            "-----",
            "I am currently working on building and developing my company, gammadata.io. We do things like:",
            "● working with the business to identify a new line of reporting that goes out to key Victorian Ministers",
            "● Gitlab/Prefect/Vault/SalesForce/BigQuery/dbt/PowerBI",
            "● Delivered a solution that has:",
            "...o High Durability of Data", 
            "...o Robust pipeline and documentation that catches issues early ",
            "...o Completely configurable, pythonic syntax for easy debugging ",
            "...o ELT architecture on top of a GCP Data Lake",
            "-----",
            "🔭 I’m currently working on GCP, CI/CD and Data Architecture",
            "🌱 I’m currently learning DBT. Always learning",
            "👯 I’m looking to collaborate on tools that make automation possible",
            "🤔 I’m looking for help with automating Data Governance",
            "💬 Ask me about Delivering Technical Projects",
            "   Read my blog here: https://medium.com/@tom.klimovski",
            "      And here: https://medium.com/@tom.klimovski_90944",
            "------",
        ],
        type: fileType.regular,
        longView: 'r--',
    },
    "cowsay": {
        name: "cowsay",
        content: [execContent],
        type: fileType.exec,
        run: execs['cowsay'],
        longView: 'r-x',
    },
    "do-not-run-me": {
        name: "do-not-run-me",
        content: [execContent],
        type: fileType.exec,
        run: execs['rick-roll'],
        longView: 'r-x',
    },
}
